<template>
  <div class="receipt">
    <div
      v-for="(item, x) of receipt"
      :key="x"
      class="receipt-block"
    >
      <p
        v-html="item.title"
        class="receipt-block__title"
      />
      <div
        v-for="(option, y) of item.options"
        :key="'o' + y"
        class="receipt-row"
      >
        <p class="receipt-row__key">{{ option.key }}</p>
        <p class="receipt-row__val">{{ option.val }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppReceipt',
  props: {
    /**
     *  EXAMPLE
     *  [
     *    {
     *      title: 'Title',
     *      options: [
     *        { key: 'key1', val: 1 },
     *        { key: 'key2', val: 2 }
     *      ]
     *    }
     *  ]
     * */
    receipt: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.receipt {
  &-block {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &__title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  &-row {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &__key {}
    &__val {}
  }
}
</style>
