<template>
  <BaseModal
    ref="modal"
  >
    <div class="modal-request" data-uid="modal-request">
      <div class="modal-request__item form">
        <h2>Оформление заявки</h2>
        <div class="content">
          <div class="content__fio">
            <BaseInput
              v-model="form.surname"
              placeholder="Фамилия"
            >
              <BaseError v-if="v$.form.$dirty && v$.form.surname.required.$invalid">
                Обязательное поле.
              </BaseError>
            </BaseInput>

            <BaseInput
              v-model="form.name"
              placeholder="Имя"
            >
              <BaseError v-if="v$.form.$dirty && v$.form.name.required.$invalid">
                Обязательное поле.
              </BaseError>
            </BaseInput>

            <BaseInput
              v-model="form.patron"
              placeholder="Отчество"
            >
              <BaseError v-if="v$.form.$dirty && v$.form.patron.required.$invalid">
                Обязательное поле.
              </BaseError>
            </BaseInput>
          </div>

          <BaseInput
            v-model="form.companyName"
            placeholder="Наименование компании"
            class="content__input"
          >
            <BaseError v-if="v$.form.$dirty && v$.form.companyName.required.$invalid">
              Обязательное поле.
            </BaseError>
          </BaseInput>

          <BaseInput
            v-model="form.email"
            placeholder="Введите ваш email"
            class="content__input"
          >
            <BaseError v-if="v$.form.$dirty && v$.form.email.required.$invalid">
              Обязательное поле.
            </BaseError>
            <BaseError v-if="v$.form.$dirty && v$.form.email.email.$invalid">
              Не валидный email.
            </BaseError>
          </BaseInput>

          <BaseInput
            v-model="form.phone"
            placeholder="Контактный телефонный номер"
            class="content__input"
          >
            <BaseError v-if="v$.form.$dirty && v$.form.phone.required.$invalid">
              Обязательное поле.
            </BaseError>
          </BaseInput>

          <BaseCheckbox
            v-model="form.condition"
            label="Я принимаю условия публичной оферты"
          >
            <template #error>
              <BaseError v-if="v$.form.$dirty && v$.form.condition.checked.$invalid">
                Обязательное поле.
              </BaseError>
            </template>
          </BaseCheckbox>

          <AppCaptcha style="margin-top: 14px;" />

          <PanelTotal
            class="panel"
            buttonText="Оставить заявку"
            :price="total"
            @open="onSubmit"
          />
        </div>
      </div>
      <div class="modal-request__item list">
        <h2>Услуги в заявке</h2>
        <div class="content">
          <slot />
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import BaseModal from '@/components/Base/BaseModal'
import BaseInput from '@/components/Base/BaseInput'
import BaseCheckbox from '@/components/Base/BaseCheckbox'
import BaseError from '@/components/Base/BaseError'
import PanelTotal from '@/components/PanelTotal'
import AppCaptcha from '@/components/AppCaptcha'

export default {
  name: 'ModalRequest',
  components: {
    AppCaptcha,
    BaseModal,
    BaseInput,
    BaseCheckbox,
    BaseError,
    PanelTotal
  },
  props: {
    total: Number
  },
  emits: {
    submit: null
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      form: {
        surname: '',
        name: '',
        patron: '',
        companyName: '',
        email: '',
        phone: '',
        condition: false
      }
    }
  },
  methods: {
    open () {
      this.$refs.modal.open()
    },
    close () {
      this.$refs.modal.close()
    },
    onSubmit () {
      if (this.v$.form.$invalid) {
        this.v$.form.$touch()
        return
      }

      this.$emit('submit', this.form)
    },
    clear () {
      this.form.surname = ''
      this.form.name = ''
      this.form.patron = ''
      this.form.companyName = ''
      this.form.email = ''
      this.form.phone = ''
      this.form.condition = false
    }
  },
  validations: {
    form: {
      surname: {
        required
      },
      name: {
        required
      },
      patron: {
        required
      },
      companyName: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required
      },
      condition: {
        checked: val => !!val
      }
    }
  }
}
</script>

<style lang="scss">
$primary-color:                           #3EBBEA !default;
$media-lg:                                1200px !default;
$media-md:                                992px !default;
$media-sm:                                768px !default;
$media-esm:                               576px !default;

.modal-request {
  display: grid;
  grid-template-columns: 7fr 5fr;
  width: 1180px;
  height: 560px;
  overflow: hidden;

  @media screen and (max-width: $media-sm) {
    height: initial;
    width: 100%;
    grid-template-columns: initial;
    display: flex;
    flex-direction: column;
  }

  &__item {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content {
      flex-grow: 1;
      overflow: hidden;
      margin-top: 40px;
    }
  }

  .form {
    padding-right: 40px;
    border-right: 2px solid #F1F2F3;

    @media screen and (max-width: $media-sm) {
      padding-right: 0;
      border-right: none;
    }

    .content {
      height: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;

      &__fio {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        margin-bottom: 15px;

        @media screen and (max-width: $media-sm) {
          grid-template-columns: 1fr;
        }
      }

      &__input {
        margin-bottom: 15px;
      }

      .panel {
        margin-top: auto;

        @media screen and (max-width: $media-sm) {
          margin-top: 40px;
        }
      }
    }
  }

  .list {
    padding-left: 40px;

    @media screen and (max-width: $media-sm) {
      padding-left: 0;
      margin-top: 40px;
    }
  }
}
</style>

<style lang="scss">
.modal-request[data-uid="modal-request"] {
  .receipt {
    height: 100%;
    overflow: auto;
    padding-right: 14px;
  }
}
</style>
