<template>
  <div class="panel">
    <BaseButton
      class="panel__btn"
      @click="$emit('open')"
    >
      {{ buttonText }}
    </BaseButton>
    <p class="panel__sum">
      Итого: <span>{{ numberWithSpaces(price) }} ₽</span>
    </p>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton'

export default {
  name: 'PanelTotal',
  components: {
    BaseButton
  },
  props: {
    price: Number,
    buttonText: {
      type: String,
      default: 'Заказать услугу'
    }
  },
  emits: ['open'],
  methods: {
    numberWithSpaces (x) {
      return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  }
}
</script>

<style lang="scss" scoped>
$media-lg:                                1200px !default;
$media-md:                                992px !default;
$media-sm:                                768px !default;
$media-esm:                               576px !default;

.panel {
  display: flex;
  align-items: center;

  @media screen and (max-width: $media-esm) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  &__btn {
    padding: 0 50px;

    @media screen and (max-width: $media-esm) {
      margin-top: 16px;
    }
  }

  &__sum {
    font-weight: 500;
    font-size: 24px;
    margin-left: 30px;

    @media screen and (max-width: $media-esm) {
      margin-left: 0;
    }

    span {
      color: $primary-color;
    }
  }
}
</style>
